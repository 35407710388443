import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import GlobeIcon from '@material-ui/icons/Public';
import DownloadIcon from '@material-ui/icons/GetApp';
import PlaceSelectConfirm from "./PlaceSelectConfirm";

import { ReactComponent as RefreshIcon } from "../images/refresh-places.svg";
import { ReactComponent as OptionsIcon } from "../images/options.svg";
import AddIncidentTagForm from "./AddIncidentTagForm";
import { useTranslation } from 'react-i18next';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: "#EBEBEB",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const CustomCheckbox = withStyles({
  root: {
    color: "#57697d",
    '&$checked': {
      color: "#57697d",
    },
  },
  checked: {},
})(Checkbox);

const CustomizedMenus = ({
  selectedOpen,
  incidentTagFormOpen,
  handleSelectedOpen,
  handleSelectedClose,
  selectedAction,
  loadingAction,
  handleCheckAll,
  handleMakeTaisaku,
  handleMarkSelectedAsDeleted,
  handlePrintSelected,
  handleExportSelected,
  getPlacesByArea,
  handlePrintViewMode,
  handleCreateIncidentTag,
  incidentTags,
  hasTaisaku,
  disabledTaisaku,
  allChecked,
  actionProgress
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOptions = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <div>
      <PlaceSelectConfirm
        open={selectedOpen}
        close={handleSelectedClose}
        handleMakeTaisaku={handleMakeTaisaku}
        handleMarkSelectedAsDeleted={handleMarkSelectedAsDeleted}
        handlePrintSelected={handlePrintSelected}
        handleExportSelected={handleExportSelected}
        selectedAction={selectedAction}
        actionProgress={actionProgress}
        loadingAction={loadingAction}
        handlePrintViewMode={handlePrintViewMode} 
      />
      <AddIncidentTagForm 
        open={incidentTagFormOpen}
        close={handleSelectedClose}
        handleCreateIncidentTag={handleCreateIncidentTag}
        loadingAction={loadingAction}
        incidentTags={incidentTags}
      />
      <Button
        variant="contained"
        onClick={() => getPlacesByArea(true)}//true will reset the filters
        style={{
          backgroundColor: "#fc3",
          marginRight: "5px"
        }}
      >
        <RefreshIcon />
        <Typography variant="h4" style={{ paddingLeft: "5px", fontSize: "15px", color: "#7D650E" }}>
          {t("Update Listings")}
        </Typography>
      </Button>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleOptions}
        style={{
          backgroundColor: "#0E6AAD",
        }}
      >
        <OptionsIcon />
        <Typography variant="h4" style={{ paddingLeft: "5px", fontSize: "15px", color: "#fff" }}>
          {t("Options")}          
        </Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <CustomCheckbox
            checked={allChecked}
            onClick={e => { handleCheckAll(e); handleClose() }}
            style={{ padding: 0, marginRight: "13px" }}
          />
          <ListItemText primary={t("Select All")} />
        </StyledMenuItem>
        {hasTaisaku && !disabledTaisaku? 
          <StyledMenuItem>
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Delete Selected")}
              onClick={() => { handleSelectedOpen("delete"); handleClose() }}
            />
          </StyledMenuItem>:
          <StyledMenuItem>
            <ListItemIcon>
              <FolderOpenIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Set as Resolved")}
              onClick={() => { handleSelectedOpen("taisaku"); handleClose() }}
            />
          </StyledMenuItem>
        }
        <StyledMenuItem>
          <ListItemIcon>
            <CreateNewFolderIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={t("Add to Incident")}
            onClick={() => { handleSelectedOpen("incident"); handleClose() }}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={t("Print Selected")}
            onClick={() => { handleSelectedOpen("print"); handleClose() }}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <GlobeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={t("Download as KML")}
            onClick={() => { handleSelectedOpen("export"); handleClose() }}
          />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
export default CustomizedMenus